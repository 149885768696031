<template>
  <f7-page name="add-item">
    <f7-navbar title="Add Item"></f7-navbar>
    <f7-block>
      <h1 class="brow_title">This is Add Item page</h1>
    </f7-block>

    <f7-block>
      <f7-input
        label="Textarea"
        type="textarea"
        placeholder="Paste url here"
        :value="url"
        @input="url = $event.target.value">
      </f7-input>
    </f7-block>
   
    <!-- <f7-block>
      <f7-button fill @click="$refs.actionsOneGroup.open()">Open Browser</f7-button>
    </f7-block> -->

    <!-- <f7-actions ref="actionsOneGroup"> -->
    <f7-actions class="lower_menu">
      <f7-actions-group>
        <!-- <f7-actions-label>Title</f7-actions-label> -->
        <f7-actions-button bold><span class="material-icons v-align">camera_alt</span>&emsp;Add a Photo</f7-actions-button>
        <f7-actions-button bold><span class="material-icons v-align">view_week</span>&emsp;Scan a Barcode</f7-actions-button>
        <f7-actions-button bold @click="searchTheWeb"><span class="material-icons v-align">language</span>&emsp;Search the Web</f7-actions-button>
        <f7-actions-button style="opacity: 0; margin-bottom: 10px;"></f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <!-- <f7-fab position="right-bottom" slot="fixed" color="orange">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button label="Search the Web"><span class="material-icons">language</span></f7-fab-button>
        <f7-fab-button label="Scan a barcode"><span class="material-icons">view_week</span></f7-fab-button>
        <f7-fab-button label="Add a photo"><span class="material-icons">camera_alt</span></f7-fab-button>
      </f7-fab-buttons>
    </f7-fab> -->

  </f7-page>
</template>

<script>
import { bus } from '../js/app'

export default {
  data() {
    return {
      url: ''
    }
  },
  mounted() {
    bus.$on('addItem', () => {
      this.$f7.actions.open('.lower_menu')
      // console.log(`[APP.vue] heeej`)
    })
  },
  methods: {
    searchTheWeb() {
      localStorage.setItem('state', 1)
      window.location.href = 'https://google.com'
    }
    // open_sheet() {
    //   this.$f7.sheet.open('.demo-sheet-swipe-to-close')
    // }
  },
  watch: {
    url() {
      console.log(this.url)
    }
  }
}
</script>

<style>
  .v-align {
    vertical-align: text-top;
  }
</style>