<template>
  <f7-app :params="f7params" >

    <!-- Views/Tabs container -->
    <f7-views tabs class="safe-areas">
      <!-- Tabbar for switching views-tabs -->
      <f7-toolbar tabbar labels bottom>
        
        <f7-link
          tab-link="#view-land"
          @click="reset_state"
          icon-ios="f7:tree"
          icon-aurora="f7:tree"
          icon-md="material:landscape"
          text="Land">
        </f7-link>
        <f7-link
          tab-link="#view-home"
          :class="{'tab-link-active': state==0}"
          @click="reset_state"
          icon-ios="f7:house_fill"
          icon-aurora="f7:house_fill"
          icon-md="material:home"
          text="Home">
        </f7-link>
        <f7-link
          tab-link="#view-notifications"
          @click="reset_state"
          icon-ios="f7:flag"
          icon-aurora="f7:flag"
          icon-md="material:notifications"
          text="Notifications">
        </f7-link>
        <f7-link
          tab-link="#view-add-item"
          :class="{'tab-link-active': state==1}"
          @click="bottom_sheet"
          icon-ios="f7:plus_circle_fill"
          icon-aurora="f7:plus_circle_fill"
          icon-md="material:add_circle"
          text="Add Item">
        </f7-link>
      </f7-toolbar>

      <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
      <f7-view id="view-home" main tab :class="{'tab-active': state==0}" url="/"></f7-view>

      <!-- Land View -->
      <f7-view id="view-land" name="land" tab url="/land/"></f7-view>

       <!-- Notifications View -->
      <f7-view id="view-notifications" name="notifications" tab url="/notifications/"></f7-view>

      <!-- Add Item View -->
      <f7-view id="view-add-item" name="add-item" tab :class="{'tab-active': state==1}" url="/add-item/"></f7-view>
    </f7-views>
  </f7-app>
</template>

<script>
  import routes from '../js/routes.js';
  import { bus } from '../js/app'

  export default {
    data() {
      return {
        state: 0,

        // Framework7 Parameters
        f7params: {
          name: 'Brow', // App name
          theme: 'auto', // Automatic theme detection

          // App routes
          routes: routes,
          
          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        }
      }
    },
    methods: {
      bottom_sheet() {
        bus.$emit('addItem')
        // this.$f7.sheet.close('.demo-sheet-swipe-to-close')
      },
      reset_state() {
        localStorage.setItem('state', 0)
        this.state = 0
      }
    },
    mounted() {
      this.$f7ready((f7) => {
        // Call F7 APIs here
        let state = localStorage.getItem('state')
        this.state = state
      });
    }
  }
</script>