<template>
  <f7-page name="land">
    <f7-navbar title="Land"></f7-navbar>
    <f7-block>
      <h1 class="brow_title">This is Land page</h1>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {

  }
</script>